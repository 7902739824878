import React from 'react';
import { injectIntl } from 'gatsby-plugin-react-intl';
import {
    JoinTeamLandingPageImgButton,
    JoinTeamLandingItemsContainer,
} from '../kenra-storybook/index'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash.get';
import {
    Spacing,
    SharedStyles,
    Container,
} from '../kenra-storybook/index'
import parse from 'html-react-parser';
const { StPageTitle, StSectionTitle, StSectionInfo } = SharedStyles;
const JoinTheTeamLanding = ({ intl, page }) => {
    
    let sectionData = page.sectionData.fragments.map(section => {
        let returnData = {};
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value;
                    break;
                case 'Text':
                    returnData['text'] = fragmentData.value;
                    break;
                case 'LinkText':
                    returnData['link'] = fragmentData.value;
                    break;
                case 'LinkUrl':
                    returnData['url'] = fragmentData.value;
                    break;
                default:
                    break;
            }
        });
        return {
            image: returnData.img,
            text: parse(returnData.text),
            link: returnData.link,
            url: returnData.url,
        };
    });
    return (
        <>
            <Spacing>
                <Container>
                    <StPageTitle>
                        <h1>{page.title}</h1>
                    </StPageTitle>
                    <StSectionInfo>
                        {documentToReactComponents(JSON.parse(get(page, 'text.raw') || '{}'))}
                    </StSectionInfo>
                    <JoinTeamLandingItemsContainer>
                        {sectionData.map((itm, index) => {
                            return (
                                <JoinTeamLandingPageImgButton
                                    link={itm.url}
                                    title={itm.text}
                                    src={itm.image}
                                />
                            );
                        })}
                    </JoinTeamLandingItemsContainer>
                </Container>
            </Spacing>
        </>
    );
};

export default injectIntl(JoinTheTeamLanding);
