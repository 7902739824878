import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
//import TenYearsLanding from 'components/TenYearsLandingPage';
import JoinTeamLanding from 'components/JoinTheTeamLandingPage';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function JoinTheTeamLandingPage(props) {
    let {
        data: { contentfulCustomLandingPage },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulCustomLandingPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulCustomLandingPage, 'seoListing.seoTitle')}
                slug={'texture-guide'}
            />
            <JoinTeamLanding page={contentfulCustomLandingPage} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCustomLandingPage(
            node_locale: { eq: $locale }
            slug: { eq: "join-the-team" }
        ) {
            ...ContentfulCustomLandingPageFields
        }
    }
`;
